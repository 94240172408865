@import url(https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:300,400,500);
body {
  margin: 0;
  padding: 0;
  background-color: #000;
}
::-webkit-scrollbar { display: none; }
@-webkit-keyframes expand {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
  }
@keyframes expand {
    from {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
  }

